<template>
  <div>
    <div class="icon">
      <img
        v-for="icon in iconsToDisplay"
        class="icon-item"
        :key="icon.key"
        :src="icon.img"
        :alt="icon.key"
        @click="() => updateConfig(icon.key)"
      />
    </div>
    <ChangeCurrencyLocale
      :changeCurrency="this.showChangeCurrencyModal"
      :languageVersion="this.lang"
      @clicked="updateCurrencyLocale"
    />
  </div>
</template>

<script>
import i18n from "../../../../i18n";
import { mapMutations, mapState } from "vuex";
import ChangeCurrencyLocale from "./ChangeCurrencyLocale.vue";
import pl from "../../../../assets/pl512.png";
import en from "../../../../assets/uk512.png";
import de from "../../../../assets/germany.png";

const icons = [
  {
    key: "pl",
    img: pl
  },
  {
    key: "en",
    img: en
  },
  {
    key: "de",
    img: de
  }
];

export default {
  name: "LocaleComponent",
  data: function() {
    return {
      i18n,
      showChangeCurrencyModal: false
    };
  },
  components: {
    ChangeCurrencyLocale
  },
  mounted() {
    this.setLang(this.lang || i18n.locale);
  },
  computed: {
    ...mapState(["cart_id", "lang", "currency"]),
    iconsToDisplay() {
      return icons.filter(i => i.key !== this.lang);
    }
  },
  methods: {
    ...mapMutations(["setLang", "setCurrency"]),
    async updateCurrencyLocale(change, newCurrency) {
      if (change) {
        this.setCurrency(newCurrency === "pl" ? "pln" : "eur");
      }
      this.showChangeCurrencyModal = false;
    },
    updateConfig: async function(flagClicked) {
      if (
        (!window.location.pathname.split("/").includes("order") &&
          flagClicked === "pl" &&
          this.currency !== "pln") ||
        (!window.location.pathname.split("/").includes("order") &&
          flagClicked !== "pl" &&
          this.currency === "pln")
      ) {
        this.showChangeCurrencyModal = true;
      }
      this.setLang(flagClicked);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  z-index: 200;
  margin-left: 15px;
  width: 80px;
}

.icon-item {
  margin-right: 5px;
  width: 30px;
  z-index: 200;

  &:hover {
    cursor: pointer;
  }
}
</style>
