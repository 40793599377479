import { render, staticRenderFns } from "./EmptyBasket.vue?vue&type=template&id=5002834e&scoped=true"
import script from "./EmptyBasket.vue?vue&type=script&lang=js"
export * from "./EmptyBasket.vue?vue&type=script&lang=js"
import style0 from "./EmptyBasket.vue?vue&type=style&index=0&id=5002834e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_vue-template-compiler@2.7.14_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5002834e",
  null
  
)

export default component.exports