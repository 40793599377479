import { render, staticRenderFns } from "./ContactInfo.vue?vue&type=template&id=71174289&scoped=true"
import script from "./ContactInfo.vue?vue&type=script&lang=js"
export * from "./ContactInfo.vue?vue&type=script&lang=js"
import style0 from "./ContactInfo.vue?vue&type=style&index=0&id=71174289&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_vue-template-compiler@2.7.14_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71174289",
  null
  
)

export default component.exports