import { render, staticRenderFns } from "./FormDealer.vue?vue&type=template&id=3f966f24&scoped=true"
import script from "./FormDealer.vue?vue&type=script&lang=js"
export * from "./FormDealer.vue?vue&type=script&lang=js"
import style0 from "./FormDealer.vue?vue&type=style&index=0&id=3f966f24&prod&lang=scss&scoped=true"
import style1 from "./FormDealer.vue?vue&type=style&index=1&id=3f966f24&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_vue-template-compiler@2.7.14_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f966f24",
  null
  
)

export default component.exports