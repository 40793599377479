<template>
  <v-dialog :value="value" persistent :max-width="width || 290">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="modal">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    value: Boolean,
    title: String,
    text: String,
    width: Number
  },
  methods: {
    modal() {
      this.$emit("modal");
    }
  }
};
</script>
