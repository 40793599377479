<template>
  <v-app>
    <nav class="white">
      <nav-desktop class="hidden-sm-and-down" />
      <nav-mobile class="hidden-md-and-up" />
    </nav>
    <!--
    <div>
      <marquee class="marquee-ribbon">
        Dear Customers, during the Christmas and New Year period, processing
        orders including parts not available in our warehouse might take a bit
        longer than usual. We will be closed on 26th pf December 2022 and 6th of
        January 2023.
      </marquee>
    </div>
    -->
    <v-main class="white">
      <router-view :key="currency" />
    </v-main>

    <cookies />
    <footer-section />
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { configureCurrency } from "@/axios";
import axios from "axios";
import NavDesktop from "./components/navigation/desktop/Desktop";
import NavMobile from "./components/navigation/mobile/Mobile";
import FooterSection from "./components/Footer";
import Cookies from "@/components/Cookies";
import cfg from "./3c.config";
import { checkUserCountry } from "ozparts-client";

export default {
  name: "App",

  components: {
    Cookies,
    NavDesktop,
    NavMobile,
    FooterSection
  },

  computed: {
    ...mapState(["lang", "currency", "cookiesConsent"])
  },

  methods: {
    ...mapMutations(["setUserCountry", "setCurrency", "setUserRegionData"])
  },

  async created() {
    if (!this.currency) {
      this.setCurrency(this.lang === "pl" ? "pln" : "eur");
    }
    configureCurrency(this.currency);

    try {
      const country = await checkUserCountry();
      this.setUserCountry(country);
      const data = await axios
        .get(`https://ozparts2.usermd.net/api/external/source/${cfg.SOURCE}`)
        .then(res => res.data);
      this.setUserRegionData(data);
    } catch (e) {
      this.setUserCountry("PL");
    }
  },

  watch: {
    currency(val) {
      configureCurrency(val);
    }
  }
};
</script>

<style lang="scss">
body {
  --red: #ed3730;

  line-height: 1.5;
  white-space: normal;
  font-family: "Montserrat", sans-serif;
}

.marquee-ribbon {
  background-color: red;
  color: white;
  font-family: "Nunito Sans", sans-serif;
  padding: 2px 0;
  font-weight: bold;
}

.nav-button {
  font-family: "Michroma", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0.5em;
  margin: 0 0.4em;
  transition-duration: 0.5s;

  &:hover {
    background-color: var(--red);
    color: white;
    cursor: pointer;
    transition-duration: 0.2s;
  }
}

.nav-active {
  background-color: var(--red);
  color: white;
}

.container {
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
