import { render, staticRenderFns } from "./Nl.vue?vue&type=template&id=a0f8ae22&scoped=true"
var script = {}
import style0 from "./Nl.vue?vue&type=style&index=0&id=a0f8ae22&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_vue-template-compiler@2.7.14_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0f8ae22",
  null
  
)

export default component.exports