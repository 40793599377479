import { computed } from "vue";
import { MANUFACTURER } from "@/vars";
import { fields } from "@/applicationTableFields";
import { getCurrentInstance } from "vue";

const urlDictionary = {
  [MANUFACTURER.DBA]: "dba",
  [MANUFACTURER.XFORCE]: "xforce",
  [MANUFACTURER.ACS]: "xclutch",
  [MANUFACTURER.SABER]: "saber",
  [MANUFACTURER.REDARC]: "redarc",
  [MANUFACTURER.HAWK]: "hawk",
  [MANUFACTURER.ACL]: "acl",
  [MANUFACTURER.AEROFLOW]: "aeroflow",
  [MANUFACTURER.TURBOSMART]: "turbosmart",
  [MANUFACTURER.PEDDERS]: "pedders",
  [MANUFACTURER.WHITELINE]: "whiteline"
};

const scrollToTop = () =>
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: "smooth"
  });

export const useApplications = manufacturer => {
  const store = getCurrentInstance().proxy.$store;
  const router = getCurrentInstance().proxy.$router;
  const translatedField = fields[store.state.lang || "en"];
  const defaultFields = [
    translatedField.make,
    translatedField.model,
    translatedField.year,
    translatedField.variant,
    translatedField.engine
  ];

  const tableFieldsDictionary = {
    [MANUFACTURER.DBA]: [
      translatedField.make,
      translatedField.model,
      translatedField.year,
      translatedField.variant,
      translatedField.category,
      translatedField.note,
      translatedField.description
    ],
    [MANUFACTURER.TURBOSMART]: [
      translatedField.make,
      translatedField.model,
      translatedField.variant
    ],
    [MANUFACTURER.WHITELINE]: [
      translatedField.make,
      translatedField.model,
      translatedField.year
    ],
    [MANUFACTURER.XFORCE]: [
      translatedField.make,
      translatedField.model,
      translatedField.year,
      translatedField.variant
    ],
    [MANUFACTURER.PEDDERS]: [
      translatedField.make,
      translatedField.model,
      translatedField.year,
      translatedField.group,
      translatedField.parameter
    ],
    [MANUFACTURER.HAWK]: [
      translatedField.make,
      translatedField.model,
      translatedField.year,
      translatedField.variant,
      translatedField.class
    ],
    [MANUFACTURER.ACS]: defaultFields,
    [MANUFACTURER.ACL]: [
      translatedField.make,
      translatedField.model,
      translatedField.engine,
      translatedField.variant
    ],
    [MANUFACTURER.SABER]: [translatedField.category, translatedField.group],
    [MANUFACTURER.REDARC]: [translatedField.category, translatedField.group],
    [MANUFACTURER.AEROFLOW]: [
      translatedField.category,
      translatedField.group,
      translatedField.class
    ]
  };

  const createQueryObject = row => {
    const {
      make,
      model,
      year,
      variant,
      engine,
      peryear,
      categorydescription,
      groupdescription,
      classdescription
    } = row;
    switch (manufacturer) {
      case MANUFACTURER.XFORCE:
        return { make, model, variant };
      case MANUFACTURER.WHITELINE:
        return { make, model, year };
      case MANUFACTURER.ACL:
        return { make, engine, variant };
      case MANUFACTURER.TURBOSMART:
        return { make, peryear: peryear[peryear.length - 1], model, variant };
      case MANUFACTURER.DBA:
        return {
          make,
          year,
          model,
          variant
        };
      case MANUFACTURER.PEDDERS:
        return { make, model, year };
      case MANUFACTURER.HAWK:
        return make
          ? { make, model, peryear: peryear[peryear.length - 1], variant }
          : { category: categorydescription, group: groupdescription };
      case MANUFACTURER.ACS:
        return {
          make,
          model,
          peryear: peryear[peryear.length - 1],
          variant,
          engine
        };
      case MANUFACTURER.SABER:
      case MANUFACTURER.REDARC:
        return { category: categorydescription, group: groupdescription };
      case MANUFACTURER.AEROFLOW:
        return { category: categorydescription, class: classdescription };
    }
  };

  const createParams = row => {
    const query = createQueryObject(row);
    const params = { brand: urlDictionary[manufacturer] };

    return [params, query];
  };

  const goToCatalogue = rowClicked => {
    const [params, query] = createParams(rowClicked[0]);
    scrollToTop();
    router.push({ name: "catalogue", params, query });
  };

  const tableFields = computed(
    () => tableFieldsDictionary[manufacturer] || defaultFields
  );

  return {
    tableFields,
    scrollToTop,
    createParams,
    goToCatalogue
  };
};
